import { Directive, ViewContainerRef, TemplateRef, Renderer } from '@angular/core';
import { autorun } from 'mobx';
import {MobxAutorunDirective} from './mobx-autorun.directive';


export class MobxAutorunSyncDirective extends MobxAutorunDirective {
  constructor(
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    protected renderer: Renderer) {super(templateRef, viewContainer, renderer); }

  autoDetect(view) {
    console.warn('mobxAutorunSync is deprecated, please use mobxAutorun instead - it\'s doing exactly the same thing');

    this.dispose = autorun(() => {
      view['detectChanges']();
    });
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{ selector: '[mobxAutorunSync]' }, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: TemplateRef, },
{type: ViewContainerRef, },
{type: Renderer, },
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
