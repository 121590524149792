import { Directive, ViewContainerRef, TemplateRef, Input, Renderer } from '@angular/core';
import { reaction } from 'mobx';
import {MobxAutorunDirective} from './mobx-autorun.directive';


export class MobxReactionDirective extends MobxAutorunDirective {
   mobxReaction;

  constructor(
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    protected renderer: Renderer) {super(templateRef, viewContainer, renderer); }

  autoDetect(view) {
    this.dispose = reaction(this.mobxReaction, () => {
      view['detectChanges']();
    }, { fireImmediately: true });
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{ selector: '[mobxReaction]' }, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: TemplateRef, },
{type: ViewContainerRef, },
{type: Renderer, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'mobxReaction': [{ type: Input },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
